// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ragnar-hub-v3-primary: mat.define-palette(mat.$indigo-palette);
$ragnar-hub-v3-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ragnar-hub-v3-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ragnar-hub-v3-theme: mat.define-light-theme((color: (primary: $ragnar-hub-v3-primary,
        accent: $ragnar-hub-v3-accent,
        warn: $ragnar-hub-v3-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ragnar-hub-v3-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Gotham;
  font-size: 12px;
  background-color: #f4f5f9;
}

// css for megamenu dropdown selectbox
::ng-deep .sy-long-custom-select {
  width: calc(100vw - 100px);
  left: -18vw !important;
  margin-top: 30px;
  transform: none !important;
  min-width: 50vw !important;
  position: absolute;

  .usage-p1-s3-select-box {
    float: left;
    width: 100vw;

    div {
      float: left;
      width: 20%;
      margin-bottom: 0px !important;

      h3 {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.38);
        font-weight: 500;
        height: 48px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e0e0e0;
        padding-left: 15px;
        margin-bottom: 0px;
      }

      ul {
        padding: 0px;
        margin: 0px;
        height: 195px;
        overflow: auto;
      }

      .mat-option {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.87);
        height: 48px;

        .mat-ripple {
          width: 100% !important;
        }
      }
    }
  }
}

// css for dropdown selectbox

::ng-deep {
  .cdk-overlay-pane {
    transform: translateX(0px) !important;
  }
}

::ng-deep {
  .sy-custom-select {
    width: 100%;
    left: 33px !important;
    margin-top: 30px;
    transform: none !important;
    margin-left: 0px;
    min-width: 100% !important;

    .mat-primary .mat-pseudo-checkbox-checked {
      background: #f47920;
    }

    .mat-option-text {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.87);
      text-transform: capitalize;
    }

    .mat-selected {
      .mat-option-text {
        color: #f47920;
      }

      .mat-select-arrow {
        color: #f47920 !important;
      }
    }

    .mat-option {
      height: 48px !important;
    }
  }

  // css for select box down icon
  .mat-focused .mat-select {
    ::ng-deep {
      .mat-select-arrow {
        color: #f47920 !important;
      }
    }
  }
}

// css for datepicker
::ng-deep .mat-calendar-body-selected {
  background-color: #f47920;
}

// css for datepicker down icon
::ng-deep .mat-icon-button {
  width: 20px !important;
  height: 20px !important;
  line-height: 30px !important;

  .mat-ripple {
    height: 20px !important;
    width: 20px;
    position: absolute;
    top: 5px;
    left: 2px;
  }

  .mat-button-focus-overlay {
    display: none;
  }
}

.no-records-found-wrapper {
  text-align: center;
  margin-top: 20px;
}

::ng-deep {

  .mat-calendar-previous-button::after,
  .mat-calendar-next-button::after {
    margin: 4.5px !important;
  }

  .space-center {
    margin: 20px auto;
    display: block;
  }
}

::ng-deep {
  .assignJob {
    .cdk-dialog-container {
      padding: 0px;
    }
  }
}
// chrome scroll-bar css
::ng-deep {

::-webkit-scrollbar {
  width: 5px;
}
 
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 15px;
  cursor: pointer;
}
}
.mat-focused {
  .mat-form-field-required-marker {
    color: #f47920 !important;
  }
}

.mat-form-field-invalid {
  &.mat-focused {
    .mat-form-field-label {
      .custom-label{
        color: #f44336 !important;
      }
    }
    .mat-form-field-required-marker {
      color:  #f44336 !important;
    }
  }
}